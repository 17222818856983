import { DataRequestModel } from "./generic-base/data-model";


export class NewsModel {
  newsAndAnnouncement: NewsViewModel;
}
export class NewsViewModel {
  headerText: string;
  shortDescription: string;
  contentText: string;
  isActive: true;
  publishDate: Date;
  catalogImage: string;
  catalogImageFileName: string;
  id: number;
}

export class NewsDataRequestModel extends DataRequestModel {
  headerTextFilter: string;
  shortDescriptionFilter: string;
  contentTextFilter: string;
  isActiveFilter: number;
  maxPublishDateFilter: string;
  minPublishDateFilter: string;
}
