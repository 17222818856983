import { DataRequestModel } from "./generic-base/data-model";

export class AnnouncementModel {
  announcement: AnnouncementViewModel;
  catalogImageFileName: string;
}

export class AnnouncementViewModel {
  headerText: string;
  shortDescription: string;
  contentText: string;
  isActive: true;
  publishDate: Date;
  catalogImage: string;
  catalogImageFileName: string;
  id: number;
}

export class AnnouncementDataRequestModel extends DataRequestModel {
  sorting: string = "publishDate desc";
  headerTextFilter: string;
  shortDescriptionFilter: string;
  contentTextFilter: string;
  isActiveFilter: number;
  maxPublishDateFilter: string;
  minPublishDateFilter: string;
}
