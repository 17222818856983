<app-header></app-header>
<main *ngIf="isPageDone">
  <section class="breadcrumb-area">
    <div class="container">
      <ul>
        <li><a href="#">Anasayfa</a></li>
        <li><a href="#">Duyurular</a></li>
      </ul>
    </div>
  </section>

  <section class="page-title">
    <div class="container">
      <h1>Duyurular</h1>
    </div>
  </section>

  <section class="announcements-list">
    <div class="container">
      <div class="row row-gap-25">
        <div
          *ngFor="let item of announcementList; let i = index"
          class="col-lg-4 col-md-6 col-sm-6"
        >
          <div class="announcements-item">
            <a [routerLink]="['/duyurular-detay/' + item.announcement.id]">
              <div class="icon">
                <span class="icon-notification"></span>
              </div>
              <div class="content">
                <div class="announcements-date">
                  <span>{{
                    item.announcement.publishDate | date: "dd.MM.yyyy"
                  }}</span>
                </div>
                <div class="announcements-title">
                  <span>{{ item.announcement.headerText }} </span>
                </div>
              </div>
            </a>
          </div>
        </div>
        <!-- <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="announcements-item">
            <a href="#">
              <div class="icon">
                <span class="icon-notification"></span>
              </div>
              <div class="content">
                <div class="announcements-date">
                  <span>01.06.2022</span>
                </div>
                <div class="announcements-title">
                  <span>Lorem Ipsum is simply dummy </span>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="announcements-item">
            <a href="#">
              <div class="icon">
                <span class="icon-notification"></span>
              </div>
              <div class="content">
                <div class="announcements-date">
                  <span>01.06.2022</span>
                </div>
                <div class="announcements-title">
                  <span>Lorem Ipsum is simply dummy </span>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="announcements-item">
            <a href="#">
              <div class="icon">
                <span class="icon-notification"></span>
              </div>
              <div class="content">
                <div class="announcements-date">
                  <span>01.06.2022</span>
                </div>
                <div class="announcements-title">
                  <span>Lorem Ipsum is simply dummy </span>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="announcements-item">
            <a href="#">
              <div class="icon">
                <span class="icon-notification"></span>
              </div>
              <div class="content">
                <div class="announcements-date">
                  <span>01.06.2022</span>
                </div>
                <div class="announcements-title">
                  <span>Lorem Ipsum is simply dummy </span>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="announcements-item">
            <a href="#">
              <div class="icon">
                <span class="icon-notification"></span>
              </div>
              <div class="content">
                <div class="announcements-date">
                  <span>01.06.2022</span>
                </div>
                <div class="announcements-title">
                  <span>Lorem Ipsum is simply dummy </span>
                </div>
              </div>
            </a>
          </div>
        </div> -->
      </div>
    </div>
  </section>

  <section class="view-more">
    <div class="container">
      <div class="view-more-btn">
        <a *ngIf="isShowBtn" (click)="initData(true)">Daha Fazla Görüntüle</a>
      </div>
    </div>
  </section>
</main>
