<app-header></app-header>
<main *ngIf="isPageDone">
  <section class="breadcrumb-area">
    <div class="container">
      <ul>
        <li><a href="/">Anasayfa</a></li>
        <li><a href="#">Haberler</a></li>
      </ul>
    </div>
  </section>

  <section class="page-title">
    <div class="container">
      <h1>Haberler</h1>
    </div>
  </section>

  <div *ngIf="newsList && newsList.length > 0">
    <section class="news-list">
      <div class="container">
        <div class="row row-gap-25">
          <div
            *ngFor="let item of newsList; let i = index"
            class="col-lg-4 col-md-6 col-sm-6"
          >
            <div class="news-item">
              <a
                [routerLink]="[
                  '/haberler-detay/' + item.newsAndAnnouncement.id
                ]"
              >
                <div class="news-img">
                  <img
                    *ngIf="
                      item?.newsAndAnnouncement?.catalogImageFileName &&
                      item.newsAndAnnouncement.catalogImageFileName.length > 0
                    "
                    src="{{
                      getDownloadUrl(item.newsAndAnnouncement.catalogImage)
                    }}"
                    alt=""
                  />
                  <img
                    *ngIf="!item?.newsAndAnnouncement?.catalogImageFileName"
                    src="../../../assets/images/haber-default.png"
                    alt=""
                  />
                  <div class="overlay">
                    <span class="text">Görüntüle</span>
                    <span class="icon-brush-right-arrow icons"></span>
                  </div>
                </div>
                <div class="news-date">
                  <span>{{
                    item.newsAndAnnouncement.publishDate | date: "dd.MM.yyyy"
                  }}</span>
                </div>
                <div class="news-title">
                  <span>{{ item.newsAndAnnouncement.headerText }}</span>
                </div>
              </a>
            </div>
          </div>
          <!-- <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="news-item">
            <a href="#">
              <div class="news-img">
                <img src="https://picsum.photos/200/300" alt="" />
                <div class="overlay">
                  <span class="text">Görüntüle</span>
                  <span class="icon-brush-right-arrow icons"></span>
                </div>
              </div>
              <div class="news-date">
                <span>01.06.2022</span>
              </div>
              <div class="news-title">
                <span>Lorem Ipsum is simply dummy </span>
              </div>
            </a>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="news-item">
            <a href="#">
              <div class="news-img">
                <img src="https://picsum.photos/200/300" alt="" />
                <div class="overlay">
                  <span class="text">Görüntüle</span>
                  <span class="icon-brush-right-arrow icons"></span>
                </div>
              </div>
              <div class="news-date">
                <span>01.06.2022</span>
              </div>
              <div class="news-title">
                <span
                  >Lorem Ipsum is simply dummy Lorem Ipsum is simply dummy
                </span>
              </div>
            </a>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="news-item">
            <a href="#">
              <div class="news-img">
                <img src="https://picsum.photos/200/300" alt="" />
                <div class="overlay">
                  <span class="text">Görüntüle</span>
                  <span class="icon-brush-right-arrow icons"></span>
                </div>
              </div>
              <div class="news-date">
                <span>01.06.2022</span>
              </div>
              <div class="news-title">
                <span>Lorem Ipsum is simply dummy </span>
              </div>
            </a>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="news-item">
            <a href="#">
              <div class="news-img">
                <img src="https://picsum.photos/200/300" alt="" />
                <div class="overlay">
                  <span class="text">Görüntüle</span>
                  <span class="icon-brush-right-arrow icons"></span>
                </div>
              </div>
              <div class="news-date">
                <span>01.06.2022</span>
              </div>
              <div class="news-title">
                <span>Lorem Ipsum is simply dummy </span>
              </div>
            </a>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
          <div class="news-item">
            <a href="#">
              <div class="news-img">
                <img src="https://picsum.photos/200/300" alt="" />
                <div class="overlay">
                  <span class="text">Görüntüle</span>
                  <span class="icon-brush-right-arrow icons"></span>
                </div>
              </div>
              <div class="news-date">
                <span>01.06.2022</span>
              </div>
              <div class="news-title">
                <span
                  >Lorem Ipsum is simply dummy Lorem Ipsum is simply dummy
                </span>
              </div>
            </a>
          </div>
        </div> -->
        </div>
      </div>
    </section>

    <section class="view-more">
      <div class="container">
        <div class="view-more-btn">
          <a *ngIf="isShowBtn" (click)="initData(true)">Daha Fazla Görüntüle</a>
        </div>
      </div>
    </section>
  </div>
</main>
