import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnnouncementModel, AnnouncementViewModel } from 'src/app/models';
import { AnnouncementService } from 'src/app/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-announcemenet-detail',
  templateUrl: './announcemenet-detail.component.html'
})
export class AnnouncemenetDetailComponent implements OnInit {

  isPageDone = false;
  id: number;
  model: AnnouncementModel;

  constructor(
    private service: AnnouncementService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(param => {
      this.id = param["id"];
      this.service.getPublicAnnouncement(this.id).subscribe(result => {
        this.model = result;
        console.log(this.model);
        this.isPageDone = true;
      })
    })
  }

  getDownloadUrl(id: string): string {
    if (id != null) {
      return environment.apiRoot + '/File/DownloadBinaryFile?id=' + id;
    }
    return "";
  }
}
