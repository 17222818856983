export class DataRequestModel {

  constructor() {
    this.maxResultCount = 2;
    this.skipCount = 0;
  }
  search: any;
  sorting: string;
  sortField: string;
  skipCount: number;
  maxResultCount: number;
}

export class DataResponseModel {
  totalCount: number;
  items: any[];
}

export class DataResponseGenericModel<T> {
  totalCount: number;
  items: T[];
}
