import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './components/main/main.component';
import { NewsComponent } from './components/news/news.component';
import { AnnouncementComponent } from './components/announcement/announcement.component';
import { ContactDetailComponent } from './components/contact-detail/contact-detail.component';
import { WhoAreWeDetailComponent } from './components/who-are-we-detail/who-are-we-detail.component';
import { NewsDetailComponent } from './components/news/news-detail/news-detail.component';
import { AnnouncemenetDetailComponent } from './components/announcement/announcemenet-detail/announcemenet-detail.component';
import { BannerSloganComponent } from './components/banner-slogan/banner-slogan.component';
import { BannerWidgetComponent } from './components/banner-widget/banner-widget.component';
import { BeAMentorComponent } from './components/be-a-mentor/be-a-mentor.component';
import { OrphanSupportProgramComponent } from './components/orphan-support-program/orphan-support-program.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { InfographicsComponent } from './components/infographics/infographics.component';
import { ReportsComponent } from './components/reports/reports.component';
import { PublicationsComponent } from './components/publications/publications.component';
import { SocialSupportsComponent } from './components/social-supports/social-supports.component';
import { SocialHelpsComponent } from './components/social-helps/social-helps.component';
import { OurEventsComponent } from './components/our-events/our-events.component';
import { OurProgramsComponent } from './components/our-programs/our-programs.component';
import { OurPrinciplesComponent } from './components/our-principles/our-principles.component';
import { OurGoalComponent } from './components/our-goal/our-goal.component';
import { ContactComponent } from './components/contact/contact.component';
import { HeaderComponent } from './components/header/header.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    NewsComponent,
    AnnouncementComponent,
    ContactDetailComponent,
    WhoAreWeDetailComponent,
    NewsDetailComponent,
    AnnouncemenetDetailComponent,
    BannerSloganComponent,
    BannerWidgetComponent,
    BeAMentorComponent,
    OrphanSupportProgramComponent,
    StatisticsComponent,
    InfographicsComponent,
    ReportsComponent,
    PublicationsComponent,
    SocialSupportsComponent,
    SocialHelpsComponent,
    OurEventsComponent,
    OurProgramsComponent,
    OurPrinciplesComponent,
    OurGoalComponent,
    ContactComponent,
    HeaderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
