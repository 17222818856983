<app-header></app-header>
<main *ngIf="isPageDone">
  <section class="breadcrumb-area">
    <div class="container">
      <ul>
        <li><a href="/">Anasayfa</a></li>
        <li><a href="haberler">Haberler</a></li>
      </ul>
    </div>
  </section>

  <section class="placard-area">
    <div class="container">
      <div class="placard">
        <img
          *ngIf="
            model?.newsAndAnnouncement?.catalogImageFileName &&
            model.newsAndAnnouncement.catalogImageFileName.length > 0
          "
          src="{{ getDownloadUrl(model.newsAndAnnouncement.catalogImage) }}"
          alt=""
        />

        <img
          *ngIf="!model?.newsAndAnnouncement?.catalogImageFileName"
          src="../../../../assets/images/haber-default.png"
          alt=""
        />
      </div>
    </div>
  </section>

  <section class="page-title">
    <div class="container">
      <h1>{{ model.newsAndAnnouncement.headerText }}</h1>
    </div>
  </section>

  <section class="who-are-we-content">
    <div class="container">
      <div class="long-desc">
        <div class="row">
          <div class="col-12 mb-2">
            <p innerHTML="{{ model.newsAndAnnouncement.contentText }}"></p>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
