import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnnouncementDataRequestModel, AnnouncementModel, NewsModel } from './models';
import { AnnouncementService, NewsService } from './services';
import { UtilsService } from './services/utils/utils.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'KizilayKaptan.Public';
  isPageDone = false;
  announcementList: AnnouncementModel[] = [];


  constructor(
    public activatedRoute: ActivatedRoute,
    public utilsService: UtilsService,
    private announcementService: AnnouncementService,
    private newsService: NewsService
  ) {

  }

  ngOnInit(): void {


  }
}
