import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnnouncemenetDetailComponent } from './components/announcement/announcemenet-detail/announcemenet-detail.component';
import { AnnouncementComponent } from './components/announcement/announcement.component';
import { BannerSloganComponent } from './components/banner-slogan/banner-slogan.component';
import { BannerWidgetComponent } from './components/banner-widget/banner-widget.component';
import { BeAMentorComponent } from './components/be-a-mentor/be-a-mentor.component';
import { ContactDetailComponent } from './components/contact-detail/contact-detail.component';
import { ContactComponent } from './components/contact/contact.component';
import { InfographicsComponent } from './components/infographics/infographics.component';
import { MainComponent } from './components/main/main.component';
import { NewsDetailComponent } from './components/news/news-detail/news-detail.component';
import { NewsComponent } from './components/news/news.component';
import { OrphanSupportProgramComponent } from './components/orphan-support-program/orphan-support-program.component';
import { OurEventsComponent } from './components/our-events/our-events.component';
import { OurGoalComponent } from './components/our-goal/our-goal.component';
import { OurPrinciplesComponent } from './components/our-principles/our-principles.component';
import { OurProgramsComponent } from './components/our-programs/our-programs.component';
import { PublicationsComponent } from './components/publications/publications.component';
import { ReportsComponent } from './components/reports/reports.component';
import { SocialHelpsComponent } from './components/social-helps/social-helps.component';
import { SocialSupportsComponent } from './components/social-supports/social-supports.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { WhoAreWeDetailComponent } from './components/who-are-we-detail/who-are-we-detail.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent
  },
  {
    path: 'haberler',
    component: NewsComponent
  },
  {
    path: 'haberler-detay/:id',
    component: NewsDetailComponent
  },
  {
    path: 'duyurular',
    component: AnnouncementComponent
  },
  {
    path: 'duyurular-detay/:id',
    component: AnnouncemenetDetailComponent
  },
  {
    path: 'iletisim',
    component: ContactComponent
  },
  {
    path: 'biz-kimiz',
    component: WhoAreWeDetailComponent
  }, {
    path: 'banner-slogan',
    component: BannerSloganComponent
  },
  {
    path: 'banner-widget',
    component: BannerWidgetComponent
  },
  {
    path: 'mentor-ol',
    component: BeAMentorComponent
  },
  {
    path: 'inografikler',
    component: InfographicsComponent
  },
  {
    path: 'yetim-destek-programi',
    component: OrphanSupportProgramComponent
  },
  {
    path: 'etkinliklerimiz',
    component: OurEventsComponent
  },
  {
    path: 'ilkelerimiz',
    component: OurPrinciplesComponent
  },
  {
    path: 'hedeflerimiz',
    component: OurGoalComponent
  },
  {
    path: 'programlarimiz',
    component: OurProgramsComponent
  },
  {
    path: 'yayinlar',
    component: PublicationsComponent
  },
  {
    path: 'sosyal-destekler',
    component: SocialSupportsComponent
  },
  {
    path: 'sosyal-yardimlar',
    component: SocialHelpsComponent
  },
  {
    path: 'istatistikler',
    component: StatisticsComponent
  },
  {
    path: 'raporlar',
    component: ReportsComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
