<app-header></app-header>
<main>
  <section class="breadcrumb-area">
    <div class="container">
      <ul>
        <li><a href="/">Anasayfa</a></li>
        <li><a href="#">Biz Kimiz?</a></li>
      </ul>
    </div>
  </section>

  <section class="page-title">
    <div class="container">
      <h1>{{ model?.publicContent?.headerText }}</h1>
    </div>
  </section>

  <section class="who-are-we-content">
    <div class="container">
      <div
        class="short-desc"
        innerHTML="{{ model?.publicContent?.shortContent }}"
      ></div>
      <div
        class="long-desc"
        innerHTML="{{ model?.publicContent?.content }}"
      ></div>
    </div>
  </section>
</main>
