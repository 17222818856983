<router-outlet></router-outlet>

<footer>
  <div class="container">
    <div class="logo">
      <a href="#">
        <img src="../assets/images/footer-logo.png" alt="" />
      </a>
    </div>
    <div class="copyright">
      <span>2022 © Türk Kızılay. Tüm hakları saklıdır.</span>
    </div>
  </div>
</footer>
