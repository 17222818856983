import { Component, OnInit } from '@angular/core';
import { AnnouncementModel, AnnouncementDataRequestModel } from 'src/app/models';
import { AnnouncementService } from 'src/app/services';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.css']
})
export class AnnouncementComponent implements OnInit {

  isPageDone = false;
  isShowBtn = true;
  announcementList: AnnouncementModel[] = [];

  constructor(
    public utilsService: UtilsService,
    private announcementService: AnnouncementService
  ) { }

  ngOnInit(): void {
    this.initData(false);
  }


  skipCount = 0;

  initData(isMore: boolean): void {
    let announcementRequestModel = new AnnouncementDataRequestModel();
    announcementRequestModel.maxResultCount = 6;

    if (isMore) {
      this.skipCount++;
      announcementRequestModel.skipCount = this.skipCount * announcementRequestModel.maxResultCount;
    }

    let announcementParamsString = this.utilsService.urlSearchParamConvertToString(announcementRequestModel);

    const announcementPromise$ = this.announcementService.getActivePublicAll(announcementParamsString).toPromise();
    Promise.all([
      announcementPromise$
    ]).then(result => {
      result[0].items.forEach(element => {
        this.announcementList.push(element);
      });
      if (this.announcementList.length >= result[0].totalCount) {
        this.isShowBtn = false;
      }
      this.isPageDone = true;

    })
  }

}
