import { DataRequestModel } from "./generic-base/data-model";


export class ContentInfoModel {
  publicContent: ContentInfoViewModel;
}
export class ContentInfoViewModel {
  publicContentType: number;
  headerText: string;
  shortContent: string;
  content: true;
  id: number;
}
