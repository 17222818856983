import { Component, OnInit } from '@angular/core';
import { NewsModel, AnnouncementDataRequestModel } from 'src/app/models';
import { NewsService } from 'src/app/services';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  isPageDone = false;
  isShowBtn = true;
  newsList: NewsModel[] = [];

  constructor(
    public utilsService: UtilsService,
    private newsService: NewsService
  ) { }

  ngOnInit(): void {
    this.initData(false);
  }


  skipCount = 0;

  initData(isMore: boolean): void {
    let newsRequestModel = new AnnouncementDataRequestModel();
    newsRequestModel.maxResultCount = 6;

    if (isMore) {
      this.skipCount++;
      newsRequestModel.skipCount = this.skipCount * newsRequestModel.maxResultCount;
    }

    let newsParamsString = this.utilsService.urlSearchParamConvertToString(newsRequestModel);

    const newsPromise$ = this.newsService.getActivePublicAll(newsParamsString).toPromise();
    Promise.all([
      newsPromise$
    ]).then(result => {
      result[0].items.forEach(element => {
        this.newsList.push(element);
      });
      if (this.newsList.length >= result[0].totalCount) {
        this.isShowBtn = false;
      }
      this.isPageDone = true;
    })
  }

  getDownloadUrl(id: string): string {
    return environment.apiRoot + '/File/DownloadBinaryFile?id=' + id;
  }
}
