<app-header></app-header>
<main>
  <section class="breadcrumb-area">
    <div class="container">
      <ul>
        <li><a href="#">Anasayfa</a></li>
        <li><a href="iletisim">İletişim</a></li>
      </ul>
    </div>
  </section>

  <section class="page-title">
    <div class="container">
      <h1>İletişim</h1>
    </div>
  </section>

  <section class="contact-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 order-lg-0 order-1">
          <div class="img">
            <img src="https://picsum.photos/700/700" alt="" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="contact-information">
            <div class="info-item">
              <div class="title">
                <span>Adresimiz</span>
              </div>
              <div class="desc">
                <span
                  >Ataç-1 Sokak No: 32 Yenişehir 06420 ANKARA / TÜRKİYE</span
                >
              </div>
            </div>
            <div class="info-item">
              <div class="title">
                <span>Telefon</span>
              </div>
              <div class="desc">
                <span> +90 312 584 18 68</span>
              </div>
            </div>
            <div class="info-item">
              <div class="title">
                <span>Fax</span>
              </div>
              <div class="desc">
                <span>+90 312 430 01 75</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
