import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataResponseModel } from 'src/app/models/generic-base/data-model';
import { AnnouncementModel, AnnouncementViewModel } from 'src/app/models/announcement';
import { environment } from 'src/environments/environment';
import { GenericCrudService } from './generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService extends GenericCrudService<AnnouncementModel, number> {

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/Announcements")
  }

  getActivePublicAll(params: string): Observable<DataResponseModel> {
    return this.http.get<DataResponseModel>(this.baseUrl + "/GetActivePublicAll?" + params);
  }

  getPublicAnnouncement(id: number): Observable<AnnouncementModel> {
    return this.http.get<AnnouncementModel>(this.baseUrl + "/GetPublicAnnouncementForView?id=" + id);
  }
}
