import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ContentInfoModel } from '../models/content-info';
import { GenericCrudService } from './generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class ContentInfoService extends GenericCrudService<ContentInfoModel, number> {

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/PublicContents")
  }

  getContentWithType(type: number): Observable<ContentInfoModel> {
    return this.http.get<ContentInfoModel>(this.baseUrl + "/GetContentWithType?contentType=" + type);
  }
}
