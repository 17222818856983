import { Component, OnInit } from '@angular/core';
import { ContentInfoModel } from 'src/app/models';
import { ContentTypes } from 'src/app/models/shared/content-type';
import { ContentInfoService } from 'src/app/services';

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.css']
})
export class ContactDetailComponent implements OnInit {

  model: ContentInfoModel;

  constructor(
    private service: ContentInfoService
  ) { }

  ngOnInit(): void {
    this.service.getContentWithType(ContentTypes.Iletisim).subscribe(result => {
      this.model = result;
    })
  }

}
