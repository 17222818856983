import { Component, OnInit } from '@angular/core';
import { AnnouncementDataRequestModel, AnnouncementModel, ContentInfoModel, NewsModel } from 'src/app/models';
import { ContentTypes } from 'src/app/models/shared/content-type';
import { AnnouncementService, ContentInfoService, NewsService } from 'src/app/services';
import { UtilsService } from 'src/app/services/utils/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  isPageDone = false;
  announcementList: AnnouncementModel[] = [];
  newsList: NewsModel[] = [];
  whoAreWeModel: ContentInfoModel;
  ourGoalModel: ContentInfoModel;
  ourPrincipleModel: ContentInfoModel;
  bannerSloganModel: ContentInfoModel;
  loginUrl = ""
  constructor(
    public utilsService: UtilsService,
    private announcementService: AnnouncementService,
    private newsService: NewsService,
    private contentService: ContentInfoService
  ) { }

  ngOnInit(): void {
    this.initData();
    this.loginUrl = environment.applicationTargetUrl;
  }


  initData(): void {
    let announcementParamsString = this.utilsService.urlSearchParamConvertToString(new AnnouncementDataRequestModel());
    let newsParamsString = this.utilsService.urlSearchParamConvertToString(new AnnouncementDataRequestModel());
    const announcementPromise$ = this.announcementService.getActivePublicAll(announcementParamsString).toPromise();
    const newsPromise$ = this.newsService.getActivePublicAll(newsParamsString).toPromise();
    const whoAreWePromise$ = this.contentService.getContentWithType(ContentTypes.BizKimiz).toPromise();
    const ourGoal$ = this.contentService.getContentWithType(ContentTypes.Hedefimiz).toPromise();
    const ourPrinciple$ = this.contentService.getContentWithType(ContentTypes.Ilkelerimiz).toPromise();
    const bannerSlogan$ = this.contentService.getContentWithType(ContentTypes.BannerSlogan).toPromise();

    Promise.all([
      whoAreWePromise$,
      ourGoal$,
      ourPrinciple$,
      bannerSlogan$,
      newsPromise$,
      announcementPromise$
    ]).then(result => {
      this.announcementList = result[5].items;
      this.newsList = result[4].items;
      this.whoAreWeModel = result[0];
      this.ourGoalModel = result[1];
      this.ourPrincipleModel = result[2];
      this.bannerSloganModel = result[3];

      console.log(this.newsList)
      console.log(this.announcementList)
      this.isPageDone = true;
    })
  }

  getDownloadUrl(id: string): string {
    return environment.apiRoot + '/File/DownloadBinaryFile?id=' + id;
  }
}
