export enum ContentTypes {
  BannerSlogan,
  BannerWidget,
  BizKimiz,
  MentorOl,
  YetimDestekProgrami,
  Istatistikler,
  Inografikler,
  Raporlar,
  Yayinlar,
  SosyalDestekler,
  SosyalYardimlar,
  Etkinliklerimiz,
  Programlarimiz,
  Ilkelerimiz,
  Hedefimiz,
  Iletisim,
}
