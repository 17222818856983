import { Injectable } from '@angular/core';
import { AnnouncementModel, NewsModel } from 'src/app/models';
@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  subTitle = '';
  constructor(
  ) { }

  setTitle(title: string) {
    this.subTitle = title;
  }

  getTitle() {
    return this.subTitle;
  }

  urlSearchParamConvertToString(requestModel: any): string {
    let params = new URLSearchParams();
    for (let key in requestModel) {
      params.set(key, requestModel[key]);
    }

    return params.toString();
  }
}
