import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataResponseModel } from 'src/app/models/generic-base/data-model';
import { environment } from 'src/environments/environment';
import { NewsModel } from '../models';
import { GenericCrudService } from './generic-base/generic-crud.service';

@Injectable({
  providedIn: 'root'
})
export class NewsService extends GenericCrudService<NewsModel, number> {

  constructor(
    public http: HttpClient
  ) {
    super(http, environment.apiRoot + "/api/services/app/NewsAndAnnouncements")
  }

  getActivePublicAll(params: string): Observable<DataResponseModel> {
    return this.http.get<DataResponseModel>(this.baseUrl + "/GetActivePublicAll?" + params);
  }

  getPublicNewsAndAnnouncement(id: number): Observable<NewsModel> {
    return this.http.get<NewsModel>(this.baseUrl + "/GetPublicNewsAndAnnouncementForView?id=" + id);
  }

  getDownloadUrl(id: string): Observable<string> {
    return this.http.get<string>(environment.apiRoot + "/File/DownloadBinaryFile?id=" + id);
  }
}
