import { Component, OnInit } from '@angular/core';
import { ContentInfoModel } from 'src/app/models';
import { ContentTypes } from 'src/app/models/shared/content-type';
import { ContentInfoService } from 'src/app/services';

@Component({
  selector: 'app-orphan-support-program',
  templateUrl: './orphan-support-program.component.html',
  styleUrls: ['./orphan-support-program.component.css']
})
export class OrphanSupportProgramComponent implements OnInit {

  model: ContentInfoModel;

  constructor(
    private service: ContentInfoService
  ) { }

  ngOnInit(): void {
    this.service.getContentWithType(ContentTypes.YetimDestekProgrami).subscribe(result => {
      this.model = result;
    })
  }
}
