<header class="homepage">
  <div class="container">
    <div class="d-flex justify-content-between">
      <div class="logo">
        <a href="#">
          <img src="../assets/images/logo-white.jpg" alt="" />
        </a>
      </div>
      <div class="menu-content">
        <div class="menu-top">
          <ul>
            <li>
              <a href="iletisim" class="contact">İletişim</a>
            </li>
            <li>
              <a href="{{ this.loginUrl }}" class="login">
                <span class="icon-user"></span>
                Giriş Yap
              </a>
            </li>
          </ul>
        </div>
        <div class="menu-bottom">
          <div class="mobile-menu">
            <a href="#">
              <span class="icon-mobile-menu"></span>
            </a>
          </div>
          <div class="menu">
            <ul>
              <li>
                <a href="#bizkimiz" title="Biz Kimiz?">Biz Kimiz?</a>
              </li>
              <li>
                <a href="#neYapiyoruz" title="Ne Yapıyoruz ?">Ne Yapıyoruz ?</a>
              </li>
              <li>
                <a href="#faaliyetlerimiz" title="Faaliyetlerimiz"
                  >Faaliyetlerimiz</a
                >
              </li>
              <li>
                <a href="#ilkelerimiz" title="İlkelerimiz">İlkelerimiz</a>
              </li>
              <li>
                <a href="#hedeflerimiz" title="Hedefimiz">Hedefimiz</a>
              </li>
              <li>
                <a href="haberler" title="Haberler">Haberler</a>
              </li>
              <li>
                <a href="duyurular" title="Duyurular">Duyurular</a>
              </li>
            </ul>
            <div class="mobile-menu-close">
              <a href="javascript:void(0)">
                <span class="icon-modal-close"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<section class="slider">
  <img src="../assets/images/slider-bg.jpg" alt="" />
  <div class="container">
    <div class="title">
      <span>
        <span
          [innerHTML]="bannerSloganModel?.publicContent?.shortContent"
        ></span>
      </span>
    </div>
    <div class="box-content">
      <div class="box">
        <a href="#">
          <span class="icon icon-hand-heart"></span>
          <span class="box-title">Bağışçı ol</span>
        </a>
      </div>
      <div class="box">
        <a href="#">
          <span class="icon icon-hands-heart"></span>
          <span class="box-title">Sponsor ol</span>
        </a>
      </div>
      <div class="box">
        <a href="mentor-ol">
          <span class="icon icon-mentor-heart"></span>
          <span class="box-title">Mentor ol</span>
        </a>
      </div>
    </div>
  </div>
</section>

<section id="bizkimiz" class="who-are-we">
  <div class="container">
    <div class="section-title">
      <div class="col-12 icon">
        <span class="icon-moon"></span>
      </div>
      <div class="col-12 title">
        <span>Biz Kimiz?</span>
      </div>
    </div>

    <div class="col-12 description">
      <div [innerHTML]="whoAreWeModel?.publicContent?.shortContent"></div>
    </div>
    <div class="read-more col-12">
      <a href="biz-kimiz">
        <span>Devamını Oku</span>
        <span class="icons icon-brush-right-arrow"></span>
      </a>
    </div>
  </div>
</section>

<section id="neYapiyoruz" class="what-are-we-doing">
  <div class="container">
    <div class="section-title">
      <div class="col-12 icon">
        <span class="icon-moon"></span>
      </div>
      <div class="col-12 title">
        <span>Ne Yapıyoruz?</span>
      </div>
    </div>
    <div class="box-content col-12">
      <div class="row">
        <div class="col-lg col-sm-6 flex-grow-0">
          <a href="yetim-destek-programi">
            <div class="box pink">
              <div class="icon">
                <span class="icon-cap"></span>
              </div>
              <div class="title">
                <span>Yetim Destek Programı</span>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg col-sm-6 flex-grow-0">
          <a href="istatistikler">
            <div class="box green">
              <div class="icon">
                <span class="icon-statistics"></span>
              </div>
              <div class="title">
                <span>İstatistikler</span>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg col-sm-6 flex-grow-0">
          <a href="inografikler">
            <div class="box blue">
              <div class="icon">
                <span class="icon-chart"></span>
              </div>
              <div class="title">
                <span>İnografikler</span>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg col-sm-6 flex-grow-0">
          <a href="raporlar">
            <div class="box lilac">
              <div class="icon">
                <span class="icon-file"></span>
              </div>
              <div class="title">
                <span>Raporlar</span>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg col-sm-6 flex-grow-0">
          <a href="yayinlar">
            <div class="box purple">
              <div class="icon">
                <span class="icon-news"></span>
              </div>
              <div class="title">
                <span>Yayınlar</span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="faaliyetlerimiz" class="our-activities">
  <div class="container">
    <div class="section-title">
      <div class="col-12 icon">
        <span class="icon-moon"></span>
      </div>
      <div class="col-12 title">
        <span>Faaliyetlerimiz</span>
      </div>
    </div>
    <div class="activities-list">
      <div class="activities-container">
        <div class="activities-item">
          <a href="sosyal-destekler">
            <span class="icon icon-peoples-heart"></span>
            <span class="title">Sosyal Destekler</span>
          </a>
        </div>
        <div class="activities-item">
          <a href="sosyal-yardimlar">
            <span class="icon icon-people-heart"></span>
            <span class="title">Sosyal Yardımlar</span>
          </a>
        </div>
        <div class="activities-item">
          <a href="etkinliklerimiz">
            <span class="icon icon-calendar-heart"></span>
            <span class="title">Etkinliklerimiz</span>
          </a>
        </div>
        <div class="activities-item">
          <a href="programlarimiz">
            <span class="icon icon-mentor-heart"></span>
            <span class="title">Programlarımız</span>
          </a>
        </div>
        <div class="activities-heart">
          <span class="icon-moon-heart"></span>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="ilkelerimiz" class="our-principles">
  <div class="container">
    <div class="section-title">
      <div class="col-12 icon">
        <span class="icon-moon"></span>
      </div>
      <div class="col-12 title">
        <span>İlkelerimiz</span>
      </div>
    </div>
    <div class="principles">
      <div class="row">
        <div
          class="principle-desc"
          [innerHTML]="ourPrincipleModel?.publicContent?.shortContent"
        ></div>
      </div>
    </div>
    <div class="see-all">
      <a href="ilkelerimiz">
        <span>Tümünü Gör</span>
        <span class="icons icon-brush-right-arrow"></span>
      </a>
    </div>
  </div>
</section>

<section id="hedeflerimiz" class="our-goal">
  <div class="container">
    <div class="section-title">
      <div class="col-12 icon">
        <span class="icon-moon"></span>
      </div>
      <div class="col-12 title">
        <span>Hedefimiz</span>
      </div>
    </div>

    <div class="goal-description">
      <p [innerHTML]="ourGoalModel?.publicContent?.shortContent"></p>
    </div>
    <br />
    <br />
    <br />
    <div class="see-all">
      <a href="hedeflerimiz">
        <span>Tümünü Gör</span>
        <span class="icons icon-brush-right-arrow"></span>
      </a>
    </div>
  </div>
</section>

<section class="news-announcements">
  <div class="container">
    <div class="row mr-0">
      <div class="col-lg-8">
        <div class="news">
          <div class="title">
            <span>Haberler</span>
          </div>
          <div class="news-list col-12 pl-0">
            <div class="row row-gap-xs-20">
              <div class="col-lg-6 col-sm-6" *ngFor="let item of newsList">
                <div class="news-item">
                  <a href="haberler-detay/{{ item.newsAndAnnouncement.id }}">
                    <div class="news-img">
                      <img
                        *ngIf="
                          item?.newsAndAnnouncement?.catalogImageFileName &&
                          item.newsAndAnnouncement.catalogImageFileName.length >
                            0
                        "
                        src="{{
                          getDownloadUrl(item.newsAndAnnouncement.catalogImage)
                        }}"
                        alt=""
                      />
                      <img
                        *ngIf="!item?.newsAndAnnouncement?.catalogImageFileName"
                        src="../../../assets/images/haber-default.png"
                        alt=""
                      />
                      <div class="overlay">
                        <span class="text">Görüntüle</span>
                        <span class="icon-brush-right-arrow icons"></span>
                      </div>
                    </div>
                    <div class="news-date">
                      <span>{{
                        item.newsAndAnnouncement.publishDate | date: "dd.MM.yyy"
                      }}</span>
                    </div>
                    <div class="news-title">
                      <span>{{ item.newsAndAnnouncement.headerText }} </span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="see-all col-12">
            <a routerLink="/haberler">
              <span>Tümünü Gör</span>
              <span class="icons icon-brush-right-arrow"></span>
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="announcements">
          <div class="title">
            <span>Duyurular</span>
          </div>
          <div class="announcements-list">
            <div
              class="announcements-item"
              *ngFor="let item of announcementList"
            >
              <a href="duyurular-detay/{{ item.announcement.id }}">
                <div class="icon">
                  <span class="icon-notification"></span>
                </div>
                <div class="content">
                  <div class="announcements-date">
                    <span>{{
                      item.announcement.publishDate | date: "dd.MM.yyyy"
                    }}</span>
                  </div>
                  <div class="announcements-title">
                    <span>{{ item.announcement.headerText }}</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="see-all col-12">
            <a routerLink="/duyurular">
              <span>Tümünü Gör</span>
              <span class="icons icon-brush-right-arrow"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
