<header class="homepage sub-page-header">
  <div class="container">
    <div class="d-flex justify-content-between">
      <div class="logo">
        <a href="#">
          <img src="../../../assets/images/logo-white.jpg" alt="" />
        </a>
      </div>
      <div class="menu-content">
        <div class="menu-top">
          <ul>
            <li>
              <a href="iletisim" class="contact">İletişim</a>
            </li>
            <li>
              <a href="{{ this.loginUrl }}" class="login">
                <span class="icon-user"></span>
                Giriş Yap
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header>
