import { Component, OnInit } from '@angular/core';
import { ContentInfoModel } from 'src/app/models';
import { ContentTypes } from 'src/app/models/shared/content-type';
import { ContentInfoService } from 'src/app/services';

@Component({
  selector: 'app-who-are-we-detail',
  templateUrl: './who-are-we-detail.component.html',
  styleUrls: ['./who-are-we-detail.component.css']
})
export class WhoAreWeDetailComponent implements OnInit {

  model: ContentInfoModel;

  constructor(
    private service: ContentInfoService
  ) { }

  ngOnInit(): void {
    this.service.getContentWithType(ContentTypes.BizKimiz).subscribe(result => {
      console.log(result)
      this.model = result;
    })
  }

}
