import { Component, OnInit } from '@angular/core';
import { ContentInfoModel } from 'src/app/models';
import { ContentTypes } from 'src/app/models/shared/content-type';
import { ContentInfoService } from 'src/app/services';

@Component({
  selector: 'app-social-supports',
  templateUrl: './social-supports.component.html',
  styleUrls: ['./social-supports.component.css']
})
export class SocialSupportsComponent implements OnInit {

  model: ContentInfoModel;

  constructor(
    private service: ContentInfoService
  ) { }

  ngOnInit(): void {
    this.service.getContentWithType(ContentTypes.SosyalDestekler).subscribe(result => {
      this.model = result;
    })
  }
}
